html {
    overflow: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
button {
    font-family: Gilroy;
}

body {
    font-family: Gilroy;
    background-size: cover;
    height: 659px;
    background-image: url("./assets/bg.png");
    background-size: cover;
    margin: 0;
}

.App {
    padding-top: 60px;
    display: flex;
    justify-content: center;
}

.app-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.app-container {
    width: 80%;
    max-width: 1440px;
}

.bold {
    font-weight: 600;
}

.form-heading {
    text-align: center;
    margin-bottom: 50px;
    color: #082d45;
    font-weight: bold;
    font-size: 50px;
    line-height: 65px;
}

.progress-bar {
    width: 624px;
    .progress-container {
        height: 9px;
        width: 100%;
        background-color: #80ed991a;
        border-radius: 50px;
    }

    .progress-filler {
        height: 100%;
        background-color: #57cc99;
        border-radius: inherit;
    }
}

.app-form {
    display: flex;
    justify-content: center;
    margin-top: 50px;

    .PhoneInput {
        margin-top: 12px;
        align-self: flex-start;
        width: 100%;

        .PhoneInputCountryIcon.PhoneInputCountryIcon--border {
            height: 30px;
            width: 45px;
            background-color: transparent;
            border: 0;
            box-shadow: unset;
            border-radius: 8px;
            overflow: hidden;

            display: none;
        }

        .PhoneInputInput {
            width: 100%;
            height: 40px;
            background: #f7fef8;
            border: 0;
            border-bottom: 1px solid #38a3a5;
            padding-left: 10px;
            border-radius: 0;
        }

        .flag-dropdown {
            background: white;
            border: none;
        }
        .PhoneInputCountrySelectArrow {
            margin-left: 10px;
            border-color: #151a30;
            border-bottom-width: 2px;
            border-right-width: 2px;
            opacity: 1;
            margin-right: 18px;
            border-radius: 1px;

            display: none;
        }
    }

    .form-content {
        width: 624px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    label.bold {
        text-align: left;
        width: 100%;
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
    }

    input[type="text"],
    input[type="email"],
    input[type="url"] {
        width: 100%;
        height: 40px;
        background-color: #f7fef8;
        border: 0;
        padding-left: 5px;
        border-bottom: 1px solid #38a3a5;
        margin-top: 12px;

        font-family: Gilroy;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-indent: 4px;
    }

    .form-content.error {
        input {
            background-color: #e74d3f0a;
            border-bottom: 2px solid #e74d3f;
        }
    }

    p.error-text {
        font-family: "Gilroy";
        font-style: normal;
        font-weight: 600;
        font-size: 10px;
        line-height: 14px;
        color: #e74d3f;
        align-self: flex-start;
        height: 16px;
    }

    input::placeholder {
        height: 24px;
        font-family: "Gilroy";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-indent: 4px;
        color: #c5cee0;
    }

    input.form-input#ref {
        margin-left: 30px;
        margin-top: 12px;
    }

    .inline-checkbox {
        align-self: flex-start;
        padding-left: 10px;
        margin-top: 16px;
        font-size: 14px;
        line-height: 22px;

        display: flex;
        align-items: center;

        input[type="checkbox"] {
            accent-color: #0f172a;
            margin: 0;
            margin-right: 10px;
            height: 16px;
            min-width: 16px;
        }

        span {
            margin-right: 5px;
        }
    }

    .inline-radio {
        align-self: flex-start;
        padding-left: 10px;
        margin-top: 16px;

        display: flex;
        align-items: center;

        font-size: 14px;

        input {
            margin-right: 10px;
            position: relative;
            top: -1px;
        }

        input[type="radio"]:checked:after {
            width: 3px;
            height: 3px;
            border-radius: 15px;
            top: -1px;
            left: -1px;
            position: relative;
            background-color: white;
            content: "";
            display: inline-block;
            visibility: visible;
            border: 6px solid black;
        }

        span {
            margin-right: 5px;
        }
    }

    input:focus-visible {
        border-radius: 0;
        outline: unset;
    }

    .buttons-div {
        margin-top: 60px;
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-evenly;

        button {
            padding: 15px 40px;
            border: none;
            border-radius: 8px;
            width: 115px;
            cursor: pointer;
            font-size: 16px;
            line-height: 20px;
            font-weight: 700;
        }

        .next-button {
            background-color: #082d45;
            color: white;
        }

        .back-button {
            background-color: grey;
            color: white;
        }
    }
}

.stepper {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    .step {
        height: 36px;
        width: 36px;
        border: 1px solid #57cc99;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        margin: 8px 6px;
        color: #22577a;
        font-weight: 400;
    }

    .step.completed {
        background-color: #57cc99;
        border-width: 2px;
        color: white;
        cursor: pointer;
    }

    .step.active {
        border-width: 2px;
        font-weight: 700;
        color: #57cc99;
    }
}

@media only screen and (max-width: 600px) {
    body {
        background-image: unset;
    }

    .app-content {
        display: unset;
    }

    .progress-bar {
        width: 100%;
        .progress-container {
            height: 9px;
            background-color: #80ed991a;
            border-radius: 50px;
        }

        .progress-filler {
            height: 100%;
            background-color: #57cc99;
            border-radius: inherit;
        }
    }

    .form-heading {
        font-size: 30px;
        line-height: 40px;
        margin-bottom: 20px;
    }

    .App {
        height: 712px;
    }

    .app-container {
        width: auto;
        margin-left: 16px;
        margin-right: 16px;
        max-width: 95%;
    }

    .app-form {
        label {
            width: 99%;
        }
        input[type="text"],
        input[type="email"],
        input[type="url"] {
            width: 97%;
        }

        .buttons-div {
            width: 100%;
            margin-top: 40px;
            flex-direction: column;
            gap: 10px;

            button {
                width: 100%;
            }

            .back-button {
                margin-left: 0;
            }
        }

        .PhoneInput {
            width: 100%;
            .PhoneInputInput {
                width: 100%;
            }
        }
    }

    .app-form {
        input#nature-lg {
            align-self: flex-start;
            margin-top: 4px;
        }
    }

    .stepper {
        .step {
            border: 1px solid #57cc99;
            font-weight: normal;
        }

        .step.completed {
            background-color: #57cc99;
            color: white;
            cursor: pointer;
            font-weight: bold;
        }

        .step.active {
            border: 2px solid #57cc99;
            font-weight: 600;
            color: #57cc99;
        }
    }
}
