@font-face {
    font-family: "Gilroy";
    src: local("Gilroy"), url(./Gilroy/Gilroy-Regular.woff) format("woff");
}
@font-face {
    font-family: "Gilroy";
    font-weight: 500;
    src: local("Gilroy"), url(./Gilroy/Gilroy-Medium.woff) format("woff");
}
@font-face {
    font-family: "Gilroy";
    font-weight: 600;
    src: local("Gilroy"), url(./Gilroy/Gilroy-SemiBold.woff) format("woff");
}
@font-face {
    font-family: "Gilroy";
    font-weight: bold;
    src: local("Gilroy"), url(./Gilroy/Gilroy-Bold.woff) format("woff");
}
